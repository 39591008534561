import { useEffect, useState } from "react";
import OnboardingNav from "../OnboardingNav/OnboardingNav";
import SDK from './SDK/SDK';
import CollectAPI from "./CollectAPI/CollectAPI";
import CharBot from "../ChatBot/ChatBot";
import SwaggerDoc from "./SwaggerDoc/SwaggerDoc";

const IntegrateSDK  = () => {
    const [active, setActive] = useState(1);
    const [pageStatus, setPageStatus] = useState({keyIntegrated: false, apiCollected: false, verificationCompleted: false});

    useEffect(() => {
        if (!pageStatus.keyIntegrated && !pageStatus.apiCollected && !pageStatus.verificationCompleted) {
            setActive(1);
        }
        if(pageStatus.keyIntegrated){
            setActive(2);
        }
        if(pageStatus.apiCollected){
            setActive(3);
        }
        if(pageStatus.verificationCompleted){
            setActive(4);
        }
    }, [pageStatus])

    const renderComponent = () => {
        switch (active) {
            case 1:
                return <SDK setPageStatus={setPageStatus} />
            case 2:
                return <CollectAPI setPageStatus={setPageStatus} />
            case 3:
                return <CharBot setPageStatus={setPageStatus} />;
            case 4:
                return <SwaggerDoc />;
        }
    }

    const getProgress = (status) => {
        switch (active) {
            case 1:
                return status == "active" ? " w-1/4" : " w-3/4";
            case 2:
                return status == "active" ? " w-2/4" : " w-2/4";
            case 3:
                return status == "active" ? " w-3/4" : " w-1/4";
            case 4:
                return status == "active" ? " w-full" : " w-0";
        }
    }

    return <div className="bg-[#080808] text-white w-screen no-scrollbar">
        <OnboardingNav active={active} />
        <div className="flex w-screen">
            <div className={"h-[2px] bg-[#F257A0] " + getProgress("active")} />
            <div className={"h-[2px] bg-[#353748] " + getProgress("inactive")} />
        </div>
        {renderComponent()}
    </div>
}

export default IntegrateSDK