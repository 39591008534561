import React, { useState } from "react";
import "./Sidebar.css";
import { Typography } from "@mui/material";
import CaretForward from "../../icons/caret-forward.svg";
import StopOutline from "../../icons/stop-outline.svg";
import PauseIcon from "../../icons/pause-icon.svg";
import IgnoreIcon from "../../icons/ignore.svg";
import BaseUrlModal from '../TestSuite/BaseUrlModal/BaseUrlModal';
import Assertion from '../Assertion/Assertion';

const Sidebar = ({ apis, status }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  return <div>
      <div className="sidebar-top p-3">
        <div className="flex justify-between w-1/2">
          <img
            className="sidebar-icon"
            src={CaretForward}
            alt="caret-forward"
          />
          <img className="sidebar-icon" src={StopOutline} alt="stop-outline" />
          <img className="sidebar-icon" src={PauseIcon} alt="pause-icon" />
          <img className="sidebar-icon" src={IgnoreIcon} alt="ignore-icon" />
        </div>
      </div>
      <div className="text-white bg-[#21222d] h-[100vh] p-3">
        <div className="">
          <button className="base-urls-btn" onClick={handleModalOpen}>
            View Base URLs
          </button>
        </div>
        <div className="mt-4">
          <div className="status-header">
            <Typography>Status</Typography>
          </div>
          <div className={"tc-status " + (status ? "text-[#4ADE80]" : "text-[#E84343]")}>
          <p className={status ? "text-[#4ADE80]" : "text-[#E84343]"}>{status ? "Passed" : "Failed"}</p>
        </div>
          <div className="assertion-header">
            <Typography>Test Steps</Typography>
          </div>
          <div className="tc-assertions">
            {apis?.map((api) => (
              <Assertion
                // id={test.id}
                parentId={api.apiId}
                title={api.apiTitle}
                assertion={api.assertions}
                pass={api.pass}
                isBugs={false}
              />
            ))}
          </div>
        </div>
      </div>
      <BaseUrlModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
};

export default Sidebar;
