import { Dialog, Alert, Snackbar, Button } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { PiUploadSimple, PiPlus, PiCopy } from "react-icons/pi";
import Left from "../../../icons/chevron-left.svg";
import Right from "../../../icons/chevron-right.svg";
import APICard from "./APICard/APICard";
import {
  addNamedApi,
  getAllAPIs,
  getUserApis,
  uploadPRD,
  deleteNamedUserApis,
  getResponseBody,
} from "../../../Actions/tcActions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader/Loader";
import JSONPretty from "react-json-pretty";
import AddAPIHeaders from "./AddAPIHeaders/AddAPIHeaders";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../../AuthContext";
import { HiOutlineSparkles } from "react-icons/hi2";
import Beautify from '../../../icons/beautify-icon.svg'

const CollectAPI = ({ setPageStatus }) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [addApiModalOpen, setAddApiModalOpen] = useState(false);
  const [productDescription, setProductDescription] = useState("");
  const [apiName, setApiName] = useState("");
  const [apiMethod, setApiMethod] = useState("post");
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [serverName, setServerName] = useState("");
  const [headers, setHeaders] = useState([
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
  ]);
  const [body, setBody] = useState(null);
  const [prdFile, setPrdFile] = useState(null);
  const [loadingType, setLoadingType] = useState("Uploading file");
  const [unSelectedApis, setUnSelectedApis] = useState([]);
  const [totalApis, setTotalApis] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const { loading, responseBody, message, error } = useSelector(
    (state) => state.apis
  );
  const {
    loading: sequenceLoading,
    apiNames,
    seqError,
  } = useSelector((state) => state.sequence);
  const {
    loading: userLoading,
    // user,
    userError,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { user, logout } = useAuth();
  const handleAddDetails = () => {
    setLoadingType("Collecting Unique APIs");
    dispatch(getUserApis(user?._id));
    setModalOpen(false);
  };

  useEffect(() => {
    if (apiNames) {
      setTotalApis(apiNames);
    }
  }, [apiNames]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalApis?.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentApis = totalApis?.slice(startIdx, startIdx + itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleAddApi = (e) => {
    e.preventDefault();
    setLoadingType("Adding API");
    let formattedHeaders = {};
    headers.forEach((header) => {
      formattedHeaders[header[0]] = header[1];
    });
    dispatch(
      addNamedApi({
        apiName,
        apiMethod,
        apiEndpoint,
        serverName,
        headers: formattedHeaders,
        body: JSON.parse(body),
        responseBody,
      })
    ).then(() => {
      setTotalApis([
        ...totalApis,
        {
          api_name: apiName,
          request: {
            body: body,
            headers: formattedHeaders,
            method: apiMethod,
            path: apiEndpoint,
          },
          serverName: serverName,
        },
      ]);
      // Once the first dispatch is done, dispatch getUserApis
      // dispatch(getUserApis(user?._id));
    });
    setAddApiModalOpen(false);
  };

  useEffect(() => {
    if (error || userError) {
      console.log("error", error);
      setAlertSeverity("error");
      setAlertMessage(userError ? userError : `Error adding the API: ${error}`);
      setSnackbarOpen(true);
      dispatch({
        type: "clearError",
      });
    }
  }, [error, userError, loading]);

  useEffect(() => {
    if (message && message != "APIs deleted successfully") {
      console.log("message", message);
      setAlertSeverity("success");
      setAlertMessage(message);
      setSnackbarOpen(true);
      dispatch({
        type: "clearMessage",
      });
    }
  }, [message]);

  const handleFileUpload = (e) => {
    setPrdFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(uploadPRD(formData));
  };

  const handleProceed = () => {
    setPageStatus({
      keyIntegrated: false,
      apiCollected: true,
      verificationCompleted: false,
    });
    let copy = [];
    if (unSelectedApis.length > 0) {
      totalApis.forEach((api, index) => {
        if (!unSelectedApis.includes(api)) {
          copy.push(api);
        }
      });
      dispatch(deleteNamedUserApis(copy));
    } else {
      dispatch(deleteNamedUserApis(totalApis));
    }
  };

  const handleTestAPI = () => {
    let formattedHeaders = {};
    headers.forEach((header) => {
      if (header[0] != "" && header[1] != "") {
        formattedHeaders[header[0]] = header[1];
      }
    });
    console.log(headers, formattedHeaders);
    dispatch(
      getResponseBody({
        body: JSON.parse(body),
        headers: formattedHeaders,
        apiEndpoint,
        apiMethod,
        serverName,
      })
    );
    setLoadingType("Testing API");
  };

  const handleLogout = async () => {
    await logout();
}

  return (loading && loadingType != "Uploading file") || sequenceLoading ? (
    <Loader type={loadingType} />
  ) : (
    <Fragment>
      {apiNames ? (
        <div className="px-16 py-10 w-full flex flex-col items-center no-scrollbar">
          <div className="flex self-end">
            <div
              onClick={() => setAddApiModalOpen(true)}
              className="cursor-pointer text-[#E7E7E7] bg-[#D9509B] hover:bg-[#CC3F8D] border border-1 border-[#FF86BE] rounded min-w-fit mr-2 px-4 py-1"
            >
              Add API
            </div>
            {/* <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-1">
              Need Help?
            </div> */}
            <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-1 cursor-pointer"onClick={handleLogout}>Log Out</div>

          </div>
          <div className="my-8">Please Select the APIs you want to test.</div>
          <div className="w-[70vw]">
            <div className="border border-1 border-[#3E4045] rounded-md w-full">
              <div className="flex justify-between p-4 border border-b-1 border-x-0 border-t-0 border-[#3E4045]">
                <div className="">API List</div>
                <div>
                  Number of APIs captured{" "}
                  <span className="ml-3 px-5 py-1 border border-1 border-[#F37BB3] rounded-md text-sm">
                    {totalApis?.length}
                  </span>
                </div>
              </div>
              <div className="grid grid-rows-5 grid-cols-2">
                {currentApis.map((api, index) => (
                  <APICard
                    api={api}
                    index={index}
                    unSelectedApis={unSelectedApis}
                    currentPage={currentPage}
                  />
                ))}
              </div>
            </div>
            <div className="flex items-center self-end mt-4">
              <div className="flex text-[#EFEFEF] mr-2">
                <div
                  className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-[#B0B0B0] text-sm mr-3 px-3 py-1"
                  onClick={handlePrevPage}
                >
                  <img src={Left} className="mr-2" /> Prev
                </div>
                <div
                  className={
                    "grid gap-2 mr-3 " +
                    (startIdx + itemsPerPage >= totalApis?.length
                      ? "grid-cols-2"
                      : "grid-cols-3")
                  }
                >
                  {startIdx + itemsPerPage >= totalApis?.length ? (
                    <>
                      <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                        {currentPage - 1}
                      </div>
                      <div className="border border-1 border-[#F257A0] p-1 w-[34px] bg-[#F257A0] text-center rounded-md">
                        {currentPage}
                      </div>
                    </>
                  ) : currentPage > 1 ? (
                    <>
                      <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                        {currentPage - 1}
                      </div>
                      <div className="border border-1 border-[#F257A0] p-1 w-[34px] bg-[#F257A0] text-center rounded-md">
                        {currentPage}
                      </div>
                      <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                        {currentPage + 1}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-[#F257A0] p-1 w-[34px] text-center rounded-md">
                        {currentPage}
                      </div>
                      <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                        {currentPage + 1}
                      </div>
                      <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                        {currentPage + 2}
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-sm px-3 py-1 mr-4"
                  onClick={handleNextPage}
                >
                  Next <img className="ml-2" src={Right} />
                </div>
              </div>
              <div className="text-[#EFEFEF] text-sm font-light">
                {" "}
                {startIdx + 1 === totalApis?.length
                  ? startIdx + 1
                  : startIdx + itemsPerPage > totalApis?.length
                  ? totalApis?.length
                  : startIdx + 1 + "-" + (startIdx + itemsPerPage)}{" "}
                of {totalApis?.length} Results
              </div>
            </div>
            {/* <div className="flex flex-col self-center items-center mt-8">
            <div>Confirm if all APIs are collected</div>
            <div className="flex mt-3">
              <div onClick={() => setApiCollected(true)} className="bg-[#26282F] border border-1 border-[#35383E] rounded-md px-6 py-1 mr-2 cursor-pointer">Yes</div>
              <div className="bg-[#30333C] border border-1 border-[#484B52] rounded-md px-6 py-1">No</div>
            </div>
          </div> */}
          </div>
          <div
            onClick={handleProceed}
            className="cursor-pointer w-1/3 py-2.5 mt-8 rounded-md bg-[#D9509B] hover:bg-[#CC3F8D] border border-1 border-[#FF86BE] text-center self-center"
          >
            Proceed to API verification
          </div>
          <Dialog
            maxWidth="85vw"
            open={addApiModalOpen}
            PaperProps={{
              style: {
                minHeight: "fit",
                width: "85vw",
                backgroundColor: "#17171A",
                color: "#fff",
                boxShadow: "none",
                borderRadius: "12px",
                border: "1px solid #2C2E33",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                }
              },
            }}
          >
            <div className="w-full">
              <div className="px-12 pt-8 flex justify-between items-center">
                <div className="font-semibold">Add API To The List</div>
                <div
                  onClick={() => setAddApiModalOpen(false)}
                  className="text-white cursor-pointer"
                >
                  <PiPlus className="rotate-45" />
                </div>
              </div>
              <form onSubmit={handleAddApi}>
                <div className="grid px-12 grid-cols-2 gap-4 mt-4">
                  <div className="grid grid-cols-5 gap-4 items-start">
                    <div className="col-span-3">
                      <label
                        htmlFor="outlined-required"
                        className="text-[#B0B0B0]"
                      >
                        API Name <span className="text-[#F37BB3]">*</span>
                      </label>
                      <br></br>
                      <input
                        className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                        value={apiName}
                        required
                        placeholder="Enter your API name"
                        id="outlined-required"
                        onChange={(e) => {
                          setApiName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="outlined-required"
                        className="text-[#B0B0B0]"
                      >
                        HTTP Method <span className="text-[#F37BB3]">*</span>
                      </label>
                      <br></br>
                      <select
                        defaultValue="post"
                        onChange={(e) => setApiMethod(e.target.value)}
                        name="api-method-select"
                        className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                        id=""
                      >
                        <option
                          value="post"
                          className="text-[#FCD34D] bg-green-500"
                        >
                          POST
                        </option>
                        <option value="get" className="text-[#FCD34D]">
                          GET
                        </option>
                        <option value="put" className="text-[#FCD34D]">
                          PUT
                        </option>
                        <option value="delete" className="text-[#FCD34D]">
                          DELETE
                        </option>
                        <option value="patch" className="text-[#FCD34D]">
                          PATCH
                        </option>
                      </select>
                    </div>
                    <div className="col-span-5">
                      <label
                        htmlFor="outlined-required"
                        className="text-[#B0B0B0]"
                      >
                        API Endpoint <span className="text-[#F37BB3]">*</span>
                      </label>
                      <br></br>
                      <input
                        className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                        value={apiEndpoint}
                        required
                        placeholder="/v1/test"
                        id="outlined-required"
                        onChange={(e) => {
                          setApiEndpoint(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-span-5">
                      <label
                        htmlFor="outlined-required"
                        className="text-[#B0B0B0]"
                      >
                        Server Name <span className="text-[#F37BB3]">*</span>
                      </label>
                      <br></br>
                      <input
                        className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                        value={serverName}
                        required
                        placeholder="https://servername.app"
                        id="outlined-required"
                        onChange={(e) => {
                          setServerName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-span-5">
                      <label
                        htmlFor="outlined-required"
                        className="text-[#B0B0B0]"
                      >
                        Headers <span className="text-[#F37BB3]">*</span>
                      </label>
                      <br></br>
                      <AddAPIHeaders
                        headers={headers}
                        setHeaders={setHeaders}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="border border-1 border-[#2C2E33] max-w-[40vw] rounded-lg relative">
                      <div className="bg-[#17181A] border-b border-[#2C2E33] p-3 flex justify-between rounded-t-lg items-center">
                        <div className="flex gap-1">
                          Request Body{" "}
                          <span className="text-[#F37BB3]"> *</span>
                        </div>
                        <div className="flex gap-2 items-center">
                          <img src={Beautify} title="Beautify" className="text-white w-[20px] h-[20px] cursor-pointer hover:text-[#D9509B]" 
                          onClick={() => {
                            setBody(JSON.stringify(JSON.parse(body), null, 4));
                          }}
                          />
                          <PiCopy
                            size={20}
                            className="cursor-pointer hover:text-[#D9509B]"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                JSON.stringify(body ?? {})
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="max-w-[40vw] min-h-[25vh] max-h-[30vh] overflow-scroll no-scrollbar bg-[#0F1011] rounded-b-lg text-[#B0B0B0]">
                        <textarea
                          className="w-full bg-inherit min-h-[25vh] p-3"
                          value={body}
                          placeholder='{"key": "value"}'
                          id="outlined-required"
                          onChange={(e) => {
                            setBody(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="border border-1 border-[#2C2E33] max-w-[40vw] rounded-lg relative">
                      <div className="bg-[#17181A] border-b border-[#2C2E33] p-3 flex justify-between rounded-t-lg items-center">
                        Response{" "}
                        <PiCopy
                          size={20}
                          className="cursor-pointer hover:text-[#D9509B]"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              JSON.stringify(responseBody?.data ?? {})
                            )
                          }
                        />
                      </div>
                      <div className="max-w-[40vw] min-h-[25vh] max-h-[30vh] overflow-scroll no-scrollbar px-3 py-3 bg-[#0F1011] rounded-b-lg text-[#B0B0B0]">
                        {responseBody ? (
                          <JSONPretty
                            id="json-pretty"
                            themeClassName="text-sm"
                            keyStyle="color: #E45799;"
                            data={JSON.stringify(
                              responseBody?.data ?? { message: "hi" }
                            )}
                          ></JSONPretty>
                        ) : (
                          "Test the API to see the response..."
                        )}
                      </div>
                      <div className="absolute bg-[#17181A] border border-[#2C2E33] top-14 right-3 rounded-md max-h-fit py-0.5 px-3 text-white">
                        {responseBody?.statusCode
                          ? responseBody.statusCode
                          : "__"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between text-sm px-12 py-6 border-t border-[#2C2E33] items-center mt-6">
                  <Button
                    sx={{
                      borderRadius: "6px",
                      backgroundColor: "#D9509B",
                      border: "1px solid #FF86BE",
                      padding: "6px 16px",
                      ":hover": {
                        backgroundColor: "#D9509B",
                      },
                      color: "white",
                      textTransform: "unset",
                      fontWeight: "400",
                    }}
                    onClick={handleTestAPI}
                    className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
                  >
                    Test the API
                  </Button>
                  <div className="flex text-sm">
                    <div
                      onClick={() => setAddApiModalOpen(false)}
                      className="cursor-pointer mr-3 rounded-md bg-[#2D2F37] border border-1 border-[#35383E] px-4 py-1.5"
                    >
                      Cancel
                    </div>
                    <Button
                      sx={{
                        "&.Mui-disabled": {
                          background: "#222222",
                          color: "white",
                          border: "1px solid #222222",
                        },
                        borderRadius: "6px",
                        backgroundColor: "#D9509B",
                        border: "1px solid #FF86BE",
                        padding: "6px 16px",
                        ":hover": {
                          backgroundColor: "#CC3F8D",
                        },
                        color: "white",
                        textTransform: "unset",
                        fontWeight: "400",
                      }}
                      type="submit"
                      {...(responseBody
                        ? { disabled: false }
                        : { disabled: true })}
                      className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
                    >
                      Add Details
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Dialog>
        </div>
      ) : (
        <Dialog
          maxWidth="55vw"
          open={modalOpen}
          PaperProps={{
            style: {
              minHeightheight: "fit",
              width: "55vw",
              backgroundColor: "#17171A",
              color: "#fff",
              boxShadow: "none",
              borderRadius: "12px",
              border: "1px solid #2C2E33",
            },
          }}
        >
          <div className="p-8 w-full flex flex-col">
            <div
              onClick={() => setModalOpen(false)}
              className="origin-center rotate-45 cursor-pointer text-[#B0B0B0] self-end"
            >
              <PiPlus size={20} />
            </div>
            <div className="font-semibold flex items-center">
              Provide Your Application Details{" "}
              <span className="text-[#E7E7E7] font-normal ml-3 text-sm">
                (optional)
              </span>
            </div>
            <div className="text-[#B0B0B0] text-sm mt-1">
              Our AI agent will use this information to generate context-rich
              test cases tailored to your specific API needs.
            </div>
            <textarea
              onChange={(e) => {
                setProductDescription(e.target.value);
              }}
              placeholder="Enter details about your product here"
              className="rounded-md min-h-[30vh] w-full bg-[#0F1011] border border-1 border-[#2C2E33] text-white text-sm p-4 mt-4"
            />
            <div className="my-6 flex items-center justify-start">
              {/* <div className="or-line"></div> */}
              <div className="w-[48%] h-[1px] bg-[#2C2E33]" />
              <div className="px-2">or</div>
              <div className="w-[48%] h-[1px] bg-[#2C2E33]" />
            </div>
            <div className="cursor-pointer rounded-md bg-[#26282F] hover:bg-[#2D2F37] border border-1 border-[#35383E] flex items-center px-3 py-1.5 text-sm max-w-fit">
              <span className="mr-2">
                <PiUploadSimple size={17} />
              </span>
              <input
                className=" absolute left-[70px] opacity-0 w-[120px] cursor-pointer z-20"
                onChange={(e) => handleFileUpload(e)}
                type="file"
                accept=".pdf, .doc, .docx, .txt"
                placeholder="Upload PRD File"
              />
              {prdFile ? (
                <div className="">{prdFile.name}</div>
              ) : (
                <div className="">Upload PRD File</div>
              )}
            </div>
          </div>
          <div className="p-4 pl-8 border border-t-1 border-[#2C2E33] flex justify-between w-full text-sm">
            {loading ? (
              <div className="flex items-center gap-4">
                <span className="text-[#E45799]">
                  <CircularProgress size={18} color="inherit" />
                </span>
                Uploading PRD File
              </div>
            ) : (
              <div></div>
            )}
            <div className="flex">
              <div
                onClick={handleAddDetails}
                className="cursor-pointer mr-3 rounded-md bg-[#26282F] border border-1 border-[#35383E] hover:bg-[#2D2F37] px-4 py-1.5"
              >
                Skip
              </div>
              <Button
                sx={{
                  "&.Mui-disabled": {
                    background: "#222222",
                    color: "white",
                    border: "1px solid #222222",
                  },
                  borderRadius: "6px",
                  backgroundColor: "#D9509B",
                  border: "1px solid #FF86BE",
                  padding: "6px 16px",
                  ":hover": {
                    backgroundColor: "#CC3F8D",
                  },
                  color: "white",
                  textTransform: "unset",
                  fontWeight: "400",
                }}
                onClick={handleAddDetails}
                {...(prdFile || productDescription
                  ? { disabled: false }
                  : { disabled: true })}
                className="cursor-pointer rounded-md bg-[#D9509B] hover:bg-[#CC3F8D] border border-1 border-[#FF86BE] px-4 py-1.5"
              >
                Add Details
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default CollectAPI;
