import React from "react";
import "./AddTCSidebar.css";
import { HiPlusSmall } from "react-icons/hi2";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
import { useState } from "react";
import HeaderRow from "./HeaderRow/HeaderRow";
import { Dialog, Typography, Popover } from "@mui/material";

/*

AddTCSidebar component that renders the sidebar for adding a new test case on the right side of the app.
It contains the API Details section with Request and Expected Response fields.
It also contains the Headers section with Type and Value input fields.
It includes buttons for Add and Cancel actions.

*/
const AddTCSidebar = ({setPopOpen, headerEl, headers, setHeaders}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [newHeader, setNewHeader] = useState({key: "", value: ""})

  const renderHeaders = () => {
    let headerArr = [];
    let formattedHeaders = Object.keys(headers).map(key => [key, headers[key]])
    formattedHeaders.map((header, index) => headerArr.push(<HeaderRow setPopOpen={setPopOpen} key={index} ind={index} headerKey={header[0]} val={header[1]} headers={formattedHeaders} setHeaders={setHeaders} />))
    return headerArr;
  }

  const handleAddHeader = () => {
    setHeaders({...headers, [newHeader.key]: newHeader.value})
    setNewHeader({key: "", value: ""})
    setModalOpen(false)
  }

  return (
    <div className="w-full bg-[#21222D] h-[100vh] text-white px-2 py-4 overflow-hidden">
      <div ref={headerEl} className="bg-[#171821] text-[#F37BB3] w-full py-3 flex items-center justify-center mb-4 cursor-pointer" onClick={() => setOpen(true)}> <span><HiPlusSmall /></span>Add Headers</div>
      <Typography variant="h6">Headers</Typography>
      <div className="mt-3 h-[85%] overflow-y-scroll no-scrollbar">
        {renderHeaders()}
      </div>
      <Dialog
        open={modalOpen}
        PaperProps={{
          style: {
            width: "586px",
            backgroundColor: "#21222D",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #3D3D3D",
          },
        }}
      >
        <div className="p-4">
          <div>Headers</div>
          <div className="grid grid-cols-6 gap-4 mt-4">
            <input onChange={(e) => setNewHeader({...newHeader, key: e.target.value})} type="text" placeholder="Key" className="bg-[#171821] border border-1 border-[#3D3D3D] rounded-md p-3 col-span-2" />
            <input onChange={(e) => setNewHeader({...newHeader, value: e.target.value})} type="text" placeholder="Value" className="bg-[#171821] border border-1 border-[#3D3D3D] rounded-md p-3 col-span-4" />
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button onClick={() => setModalOpen(false)} className="border text-white px-4 py-2 rounded-md">Cancel</button>
            <button onClick={handleAddHeader} className="bg-[#F37BB3] text-white px-4 py-2 rounded-md">Add</button>
          </div>
        </div>
      </Dialog>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={headerEl.current}
        slotProps={{
          paper: {
            style: {
              width: "13vw",
              backgroundColor: "#21222D",
              color: "#fff",
              boxShadow: "none",
              borderRadius: "6px",
              border: "1px solid #E5E7EB",
              maxHeight: "50vh",
              overflowY: "scroll",
            },
          }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorPosition={
          {top: 0,
          left: -500}
        }
      >
        <div onClick={() => {
          setOpen(false);
          setPopOpen({value: true, type: "header"})
        }} className="p-2 border-b border-gray-200 cursor-pointer">Import Header</div>
        <div onClick={() => setModalOpen(true)} className="p-2 cursor-pointer">Add Header</div>
      </Popover>
    </div>
  );
};

export default AddTCSidebar;
