import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CoverageComponent.css";
import { Typography } from "@mui/material";
import EndpointCoverage from "../EndpointCoverage/EndpointCoverage";
import HTTPCoverage from "../HTTPCoverage/HTTPCoverage";
import BugCoverage from "../BugCoverage/BugCoverage";
import PriorityDistribution from "../PriorityDistribution/PriorityDistribution";
import Assertion from "../Assertion/Assertion";
import EmptyList from "../../icons/empty-list.svg";

/*

CoverageComponent component that renders the coverage statistics on the test suite page.
The CoverageComponent component displays the test coverage statistics for assertions, HTTP requests, and bugs.
The component uses the Assertion, BugCoverage, EndpointCoverage, and HTTPCoverage components to display the coverage statistics.
The component takes in props such as isBugs, assertions, http, and bugs to render the coverage statistics.
The component conditionally renders the coverage statistics based on the isBugs prop.
If it is not bugs page then http coverage and endpoint coverage will be displayed.
If it is bugs page then bug coverage, priority distribution, and top 10 ageing bugs will be displayed.

*/
const CoverageComponent = ({ isBugs, test, http, bugs }) => {
  let name = isBugs ? "ageing-bugs" : "tc-assertions";
  const bugsNull = bugs != null ? false : true;
  
  return isBugs == false ? (
    <div className="coverage">
      <EndpointCoverage
        passed={test?.totalPass}
        fail={test?.totalFail}
      />
      <HTTPCoverage http={http} />
    </div>
  ) : (
    <div className="coverage">
      <BugCoverage />
      <PriorityDistribution />
      <div className={`${name}${bugsNull ? "-empty" : ""}`}>
        {bugs != null ? (
          <>
            <Typography variant="h6">Top 10 Ageing Bugs</Typography>
            <Assertion isBugs={isBugs} />
            <Assertion isBugs={isBugs} />
          </>
        ) : (
          <>
            <Typography variant="h5">Top 10 Ageing Bugs</Typography>
            <img src={EmptyList} alt="empty-list" />
            <Typography variant="h6">
              Whoops! No data<br></br> available at the moment.
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default CoverageComponent;
