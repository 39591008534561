import React, { useEffect, useState } from "react";
import "./TestSuite.css";
import CoverageComponent from "../CoverageComponent/CoverageComponent";
import IssuesTable from "../IssuesTable/IssuesTable";
import { Dialog, Typography, duration } from "@mui/material";
import DeleteIcon from "../../icons/delete-icon.svg";
import {
  fetchTC,
  fetchReqResByAPI,
  fetchTestScripts,
  getTestSteps,
  runAllTests,
  getBaseURLs
} from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../Loader/Loader";
import Navbar from "../Navbar/Navbar";
import { PiPlus } from "react-icons/pi";
import { IoAlertCircleOutline, IoPlayCircleOutline } from "react-icons/io5";
import BaseUrlModal from "./BaseUrlModal/BaseUrlModal";
import RightSidebar from "../RightSidebar/RightSidebar";
import { useAuth } from "../../AuthContext";

/*

TestSuite component that renders the test suite page of the application.
The TestSuite component uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
The TestSuite component uses the useEffect hook to fetch the test cases when the component mounts.
The TestSuite component renders the IssuesTable and CoverageComponent components.
The IssuesTable component displays the test cases in a table format.
This component renders on the /test-suite route.
The TestSuite component uses the useNavigate hook from react-router-dom to navigate to different routes.
The CoverageComponent component displays the test coverage statistics.

*/
import { config } from "../../config";

let dev = config.dev
let dburl1 =
  dev === false
    ? "https://server-v3-7qxc7hlaka-uc.a.run.app"
    : "http://localhost:3000";

const TestSuite = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const {
    loading: testsCasesLoading,
    testCases,
    testScripts,
    testSteps,
  } = useSelector((state) => state.testCases); // Get the test cases from the state

  const { user, apikey } = useAuth(); // Get the user from the AuthContext

  let modalShown = sessionStorage.getItem("modalShown");

  console.log("modalShown Initialization", modalShown);

  const [modalOpen, setModalOpen] = useState(false); // Use the modalOpen state to determine if the modal is open

  const { loading } = useSelector((state) => state.user);


  const key = apikey; // Get the key from the API key

  // const { loading: apiListLoading, apiList } = useSelector(state => state.apiList)

  const handleAddTestCase = () => {
    navigate("/add-test-case");
  };

  // function to find the last test case number
  let findLastTestCaseNumber = () => {
    let lastTestCaseNumber = 0;
    if (testCases && testCases?.tests?.length > 0) {
      testCases.tests.forEach((test) => {
        if (test.tcNumber > lastTestCaseNumber) {
          lastTestCaseNumber = test.tcNumber;
        }
      });
    }
    return lastTestCaseNumber;
  };

  let tests = []; // Create an array to store the tests
  let lastTcNumber = findLastTestCaseNumber();

  if (testCases && testCases?.tests?.length > 0) {
    localStorage.clear();
    testCases.tests.forEach((test) => {
      tests.push({
        _id: test.id,
        name: test.title,
        tcNumber: test.tcNumber,
        pass: test.pass,
        time: test.time,
        date: test.date,
        duration: test.duration,
      });
    });
    // testScripts?.script.forEach((script)=>{
    //   if (!testCases.tests.some(test => test.title == script.name)) {
    //     tests.push({...script});
    //   }
    // })
    testSteps?.test_steps.forEach((testStep) => {
      if (!testCases.tests.some(test => test.title == testStep.test_case_name)) {
        lastTcNumber++;
        tests.push({
          name: testStep.test_case_name,
          tcNumber: lastTcNumber
        });
      }
      testScripts?.script.forEach((script) => {
        if (!tests.some(test => test.name == script.name)) {
          lastTcNumber++;
          tests.push({
            name: script.name,
            tcNumber: lastTcNumber
          });
        }
      });
    });
  } else {
    testSteps?.test_steps.forEach((testStep) => {
      if (!testCases?.tests?.some(test => test.title == testStep.test_case_name)) {
        lastTcNumber++;
        tests.push({
          name: testStep.test_case_name,
          tcNumber: lastTcNumber
        });
      }
    });
    testScripts?.script.forEach((script) => {
      if (!tests.some(test => test.name == script.name)) {
        lastTcNumber++;
        tests.push({
          name: script.name,
          tcNumber: lastTcNumber
        });
      }
    });
  }
  useEffect(() => {
    dispatch(fetchTC());
    dispatch(getBaseURLs());
    dispatch(fetchTestScripts());
    let userid = user
      ? user._id
      : null;
    dispatch(getTestSteps(userid));
    dispatch(fetchReqResByAPI(userid, key));
    // Get the API key from the user
  }, []);

  useEffect(() => {

    let userid = user
      ? user._id
      : null;
    // Create a new EventSource instance to connect to the SSE endpoint
    const eventSource = new EventSource(
      `${dburl1}/events/refreshTC?userid=${userid}`,
      { withCredentials: true }
    );

    // Listen for the "refresh" event from the server
    eventSource.addEventListener("refreshTC", () => {
      // Fetch new data from another endpoint when the "refresh" event is received
      console.log("Refreshing test cases");
      let userid = user
        ? user._id
        : localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))._id
          : null;
      dispatch(getTestSteps(userid));
      dispatch(fetchTestScripts());
      dispatch(fetchTC());
    });

    // Cleanup on component unmount
    return () => {
      eventSource.close();
    };
  }, []);

  const runAllTestsHandler = async (e) => {
    try {
      // setLoading(true);
      // setLoading(false);
      await dispatch(runAllTests(key))
      setErrorModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (testScripts || testSteps) {
      if (!modalShown) {
        console.log("modalShown", modalShown);
        setErrorModalOpen(true);
        sessionStorage.setItem("modalShown", true);
      }
    }
  }, [testScripts, testSteps])

  return loading == true ? null : (
    <div className="w-full grid grid-cols-10 gap-8 no-scrollbar">
      <div className="col-span-2">
        <Navbar />
      </div>
      <Loader loading={testsCasesLoading} />
      <div className="col-span-6 py-8 flex flex-col justify-start">
        <div className="flex justify-between w-full">
          <select
            name="workspace-select"
            className="bg-[#21222D] rounded-sm px-3 py-2 min-w-[150px] text-white"
            id=""
          >
            <option selected disabled>
              Workspace
            </option>
          </select>
          <div className="flex">
            <button
              className="bg-[#21222D] rounded-sm px-6 py-2 text-white mr-3"
              onClick={() => setModalOpen(true)}
            >
              View Base URLs
            </button>
            <button
              onClick={handleAddTestCase}
              className="bg-[#21222D] rounded-sm px-6 py-1 text-white"
            >
              Add New Test Case
            </button>
          </div>
          <BaseUrlModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
        <div className="w-[90%] grid grid-cols-3 self-end my-12">
          <select
            name="repo-select"
            className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3"
            id=""
          >
            <option selected disabled>
              Repository
            </option>
          </select>
          <select
            name="branch-select"
            className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3"
            id=""
          >
            <option selected disabled>
              Branch
            </option>
          </select>
          <select
            name="commit-select"
            className="bg-[#21222D] rounded-sm px-3 py-2 text-white"
            id=""
          >
            <option selected disabled>
              Commit
            </option>
          </select>
        </div>
        <div className="testSuite-middle-bottom">
          <IssuesTable isTC={true} tests={tests} />
        </div>
      </div>
      <div className="col-span-2">
        <RightSidebar />
      </div>
      {/* <Dialog
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        PaperProps={{
          style: {
            minHeight: "fit",
            width: "50vw",
            backgroundColor: "#17171A",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #2C2E33",
          },
        }}
      >
        <div className="p-6 w-full flex flex-col">
          <div
            onClick={() => setErrorModalOpen(false)}
            className="relative right-0 text-right cursor-pointer self-end text-[#B0B0B0]"
          >
            <PiPlus className="rotate-45" size={24} />
          </div>
          <div className="flex gap-4 p-6">
            <div className="text-[#F87171]">
              <IoAlertCircleOutline size={48} />
            </div>
            <div className="flex flex-col gap-4">
              <div className="font-semibold flex items-center">Test Run Failed</div>
              <div className="text-[#B0B0B0] text-sm mt-1">
                Your test run has failed due to an unexpected issue on our end. This is a temporary problem and not related to your test configuration. Please try running the test again shortly. If the problem persists, please contact our support team for further assistance. We apologize for the inconvenience and appreciate your understanding as we work to fix this issue
              </div>
              <div onClick={runAllTestsHandler} className="cursor-pointer bg-[#F257A0] self-start rounded-md px-4 py-2 text-white mt-4 flex items-center gap-2"> <span><IoPlayCircleOutline /></span>Run Test Suite</div>
            </div>
          </div>
        </div>
      </Dialog> */}
    </div>
  );
};

export default TestSuite;
