import React, { useState } from "react";
import PlusIcon from "../../../icons/plus-icon.svg";


const APISequence = ({ allApis, setCurrentApi, addedApis, currentApi, setReqBody, setResContent, setHeaders }) => {
    const [apiCount, setApiCount] = useState(1);

  const handleApiSelection = (apiID, index) => {
    const selected = allApis.filter((api) => api.id === apiID);
    setCurrentApi(selected[0]);
    setHeaders(selected[0].source.request.headers)
    setReqBody(selected[0].source.request.body ? JSON.stringify(selected[0].source.request.body, null, 4) : 'NA');
    let formattedResContent = ""
    if (typeof(selected[0].source.response.content) === 'object') {
      formattedResContent = selected[0].source.response.content ? JSON.stringify(selected[0].source.response.content, null, 4): 'NA'
    } else {
      let temp = JSON.parse(selected[0].source.response.content ?? '{}')
      formattedResContent = JSON.stringify(temp, null, 4)
    }
    setResContent(formattedResContent);
    addedApis[index] = selected[0]
  };

  const renderApiSequence = () => {
    const apiSequence = [];
    for (let i = 0; i < apiCount; i++) {
      apiSequence.push(
        <div className="mt-4 w-full">
          <select
            value={addedApis[i] ? addedApis[i].id : 'default'}
            className={"px-4 py-3 bg-[#21222D] no-scrollbar rounded-sm w-full text-white outline-none focus:outline-none " + (i == apiCount - 1? "border border-pink-500": "")}
            onChange={(e) => handleApiSelection(e.target.value, i)}
          >
            <option value='default' disabled="disabled">Select an API</option>
            {allApis.map((api) => (
              <option value={api.id}>{api.name}</option>
            ))}
          </select>
        </div>
      );
    }
    return apiSequence;
  }

  return (
    <React.Fragment>
     {renderApiSequence()}
      <div className="tc-plus-btn" onClick={() => setApiCount(apiCount +1)}>
        <img src={PlusIcon} alt="plus-icon" />
      </div>
    </React.Fragment>
  );
};

export default APISequence;
