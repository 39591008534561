import EditIcon from "../../../icons/edit-icon.svg";
import { useState } from "react";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const BaseUrl = ({source, sources, setSources, ind, sourceLength}) => {
    const [edit, setEdit] = useState(source?.source ? false : true);
    const [newSource, setNewSource] = useState(source?.source ?? "");
    const [url, setUrl] = useState(source?.baseUrl ?? "");

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setSnackbarOpen(false);
      };

    const isValidBaseUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };

    // const isValidBaseUrl = (url) => {
    //     try {
    //       const parsedUrl = new URL(url);
    
    //       // Ensure that the URL includes a protocol and hostname
    //       return (
    //         ["http:", "https:"].includes(parsedUrl.protocol) && !!parsedUrl.hostname
    //       );
    //     } catch (e) {
    //       console.log("error", e);
    //       return false; // Invalid URL
    //     }
    //   };

    const handleSave = () => {
        let temp_sources = sources.length > 0 ? [...sources] : []
        let temp_source = source ? {...source} : {}
        if (source) {
            temp_sources.pop(source)
        }
        temp_source["source"] = newSource
        if (isValidBaseUrl(url)) {
            temp_source["baseUrl"] = url
        }
        else {
            setAlertSeverity("error")
            setAlertMessage("Invalid Base URL")
            setSnackbarOpen(true)
            return
        }
        temp_sources.push(temp_source)
        console.log(temp_sources)
        setSources(temp_sources)
        setAlertSeverity("success")
        setAlertMessage("Base URL saved successfully")
        setSnackbarOpen(true)
    }

    const handleURLSave = (e) => {
      setUrl(e.target.value)
      let temp_sources = [...sources]
      let popped_source = temp_sources.pop()
      let temp_source = {...popped_source}
      temp_source["baseUrl"] = e.target.value
      temp_sources.push(temp_source)
      setSources(temp_sources)
      console.log(temp_source)
    }

    const handleSourceSave = (e) => {
      setNewSource(e.target.value)
      let temp_sources = [...sources]
      let popped_source = temp_sources.pop()
      let temp_source = {...popped_source}
      temp_source["source"] = e.target.value
      temp_sources.push(temp_source)
      setSources(temp_sources)
      console.log(temp_source)
    }

    return <div className={"grid grid-cols-3 " + (ind == sourceLength -1 ? "" : "border-b border-[#2C2E33]")}>
    <input placeholder="Source name" onChange={handleSourceSave} disabled={!edit} value={newSource} className="col-span-1 bg-inherit rounded-l-md px-3 py-2 border-r border-[#2C2E33]" />
    <div className="px-3 py-2 col-span-2 flex items-center justify-between gap-2">
      <input type="url" pattern="https?://.*" placeholder="https://api-example.com" onChange={handleURLSave} disabled={!edit} value={url} className="bg-inherit w-full border-none overflow-x-scroll" />
      <div onClick={() => setEdit(!edit)} className="bg-[#17181A] border border-[#2C2E33] rounded-md px-3 py-2 flex justify-center items-center cursor-pointer hover:bg-[#E45799]">
          {edit ? <RiSaveLine onClick={handleSave} size={16} /> : <RiEditBoxLine size={16} />}
      </div>
    </div>
    <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
  </div>
}

export default BaseUrl;
